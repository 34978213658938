<template>
    <div class="task-detail">
        <el-breadcrumb class="crumb" separator="/">
            <span @click="goBack" class="go-back-icon"><i class="el-icon-arrow-left"/></span>
            <el-breadcrumb-item :to="{ path: '/offline_task' }" class="bucket-crumb">离线任务管理</el-breadcrumb-item>
            <el-breadcrumb-item > {{ headerDesc }} </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="detail-container">
            <el-tabs v-model="activeName" @tab-click="handleTabsClick" style="background: #fff;">
                <el-tab-pane label="任务流程" name="1">
                    <TaskProcess v-if="activeName === '1'"/>
                </el-tab-pane>
                <el-tab-pane label="任务详情" name="2">
                    <TaskView v-if="activeName === '2'"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import TaskProcess from "@/views/offline/components/TaskProcess";
import TaskView from "@/views/offline/components/TaskView";
export default {
    name: "TaskDetail",
    components: { TaskProcess, TaskView},
    data () {
        return {
            taskId: '',
            activeName: '1',
        }
    },
    computed: {
        headerDesc () {
            return this.activeName === '1' ? '任务流程' : '任务详情'
        }
    },
    mounted () {
        this.activeName = this.$route.query.type || '1'
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped lang="scss">
.task-detail {
    margin: 20px;
    background: #fff;
    text-align: left;

}

.crumb {
    padding: 4px 26px 0;
}

.detail-container {
    min-height: calc(100% - 136px);
    background: #f4f4f4;
    text-align: left;
    position: relative;
}
</style>