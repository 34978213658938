<template>
    <el-dialog
        custom-class="task-form" title="打印预览" append-to-body
        :close-on-click-modal="false"
        :visible.sync="showDialog" width="800px" style="min-height: 528px;"
    >
        <div class="list">
            <div class="item" v-for="item in dataList">
                <div class="item-row"> <span>RFID：{{ item.rfid }}</span>  <span>{{ item.createTime }}</span> </div>
                <div class="item-row"> <span> 盘库：{{ item.das }}</span>  <span>{{ item.bucket }}</span> </div>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button :loading="loading" size="small" @click="handleDownload" type="primary">下载</el-button>
        </div>

    </el-dialog>
</template>

<script>
import {downloadZip} from "@/utils";
import {getDownloadFileName} from "@/views/offline/js";

export default {
    name: "PrintPreview",
    data () {
        return {
            showDialog: false,
            loading: false,
            dataList: [
            ],
            taskId: ''
        }
    },
    methods: {
        show (taskId) {
            this.showDialog = true
            this.taskId = taskId
            this.getData();
        },
        getData () {
            this.$get(`/rfTask/label/${this.taskId}`)
            .then(res => {
                if (res.code === '200') {
                    this.dataList = res.data
                }
            })
        },
        handleClose () {
            this.showDialog = false
        },
        handleDownload () {
            // /rfTask/label/{taskId}/download
            this.$postJson('/rfTask/label/download?taskId=' + this.taskId)
            .then(res => {
                downloadZip(res.data, getDownloadFileName(res) || '标签.xls')
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 20px;
        .item {
            width: 50%;
            text-align: center;
            padding: 20px 0;
            line-height: 28px;
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            font-size: 12px;
            .item-row {
                display: flex;
                justify-content: flex-start;
                padding: 0 10px;
                text-align: left;
                span:first-child {
                    width: 160px;
                    margin-right: 10px;
                }
            }

        }
        .item:nth-child(2n) {
            border-right: 1px solid #ccc;
        }
        .item:nth-last-child(2) {
            border-bottom: 1px solid #ccc;
        }
        .item:last-child {
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }
    }
</style>