<template>
    <el-dialog
        custom-class="remark-list" title="任务备注" append-to-body
        :close-on-click-modal="false"
        :visible.sync="showDialog" width="800px" style="min-height: 528px;"
    >
        <div class="item" v-for="item in list">
            <h4 class="title" v-if="item.status === 'CANCEL'">[{{ taskStatusDesc(item.step) }}-<span class="color_fa5050">任务取消</span>]</h4>
            <h4 class="title" v-else>[{{ taskStatusDesc(item.step) }}]</h4>
            <div class="desc">{{ item.remark }} ({{ moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') }})</div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button :loading="loading" size="small" @click="handleClose" type="primary">关闭</el-button>
        </div>

    </el-dialog>
</template>

<script>
import {taskStatusDesc} from "@/views/offline/js";
import moment from "moment";
export default {
    name: "PrintPreview",
    props: ['taskId'],
    data () {
        return {
            showDialog: false,
            loading: false,
            list: [
                // {title: '定位数据所在盘匣', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '确定离线盘匣', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '标签打印', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '取出盘匣', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '录入存储位置', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '任务完成', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
                // {title: '任务取消', desc: '这里是流程中的备注信息这里是流程中的备注信息'},
            ]
        }
    },
    mounted() {
    },
    methods: {
        taskStatusDesc,
        moment,
        getData () {
            this.$get(`/rfTask/log/${this.taskId}`)
            .then(res => {
                if (res.code === '200') {
                    this.list = res.data
                }
            })
        },
        show () {
            this.showDialog = true
            this.getData()
        },
        handleClose () {
            this.showDialog = false
        }
    }
}
</script>

<style scoped lang="scss">

.item {
    margin-top: 10px;
    .title {
        margin-bottom: 4px;
        color: #333333d9;
        font-size: 14px;
        line-height: 1.5715;
    }
    .desc {
        color: #00000073;
        font-size: 14px;
        line-height: 1.5715;
    }
}

</style>