<template>
    <div class="task-process" v-loading="rightLoading">
        <ul class="left">
            <li :class="{'project-view-process-item':true, 'is-last': taskInfo.status !== 'CANCEL' && i.status === 6}" v-for="i in steps">
                <div class="view">
                    <div class="line"/>
                    <div class="dot ing" v-if="i.status === step && taskInfo.status !== 'CANCEL'">
                        <span class="outer"></span>
                        <span class="inner"></span>
                    </div>
                    <div class="dot" v-else>
                        <span :class="{'outer': true, notstart: i.status > step}"></span>
                        <span :class="{'inner': true, notstart: i.status > step}"></span>
                    </div>

                    <div class="desc">{{ i.desc }}</div>
                    <div class="status-desc" v-show="i.status > step">未开始</div>
                    <div class="status-desc" v-show="i.status === step">{{
                            taskInfo.status === 'CANCEL' ? '已取消' : (step === 1 ? (!taskInfo.analyzeFlag ? '分析中': '分析完成') : '进行中')
                        }}
                        <i 
                            v-show="step === 1 && !taskInfo.analyzeFlag" 
                            style="cursor: pointer;color: #ff8700;" 
                            :class="{'el-icon-refresh': true, 'animation': true}" ></i>
                    </div>
                    <div class="status-desc" v-show="i.status < step">已完成</div>
                </div>
            </li>
            <li class="project-view-process-item" v-show="taskInfo.status === 'CANCEL'">
                <div class="view">
                    <div class="line"/>
                    <div class="dot">
                        <span :class="{'outer': true}"></span>
                        <span :class="{'inner': true}"></span>
                    </div>

                    <div class="desc">任务取消</div>
                </div>
            </li>
        </ul>
        <div class="right" v-show="taskInfo.status !== 'CANCEL'">
            <el-alert
                v-show="step === 2"
                class="alert"
                type="warning"
                style="margin: 10px 0;"
                show-icon
                :closable="false">
                <div slot="title">
                    <p>请根据盘匣内存储文件清单，选择最终要执行离线任务的盘匣！该流程仅针对未进行过离线任务的盘匣，如果是二次离线请在“离线盘匣管理”模块申请！</p>
                    <!--                    <p></p>-->
                </div>
            </el-alert>

            <el-table
                v-show="step === 2"
                :data="tableData"
                v-loading="loadingData"
                ref="fileTableRef"
                style="width: 100%"
				@select-all="handleSelectionChange"
                @select="handleSelectionChange" border>
                <el-table-column align="left" type="selection" :selectable="handleSelectable"  width="45"></el-table-column>
                <el-table-column align="center" prop="rfid" label="盘匣RFID号"></el-table-column>
                <el-table-column align="center" prop="offlineSizePercent" label="离线文件占比"></el-table-column>
                <el-table-column align="center" prop="files" label="文件清单">
                    <template slot-scope="{row}">
                        <el-button type="text" @click="fileInfoPreview(row)">预览</el-button>
                        <el-button type="text" @click="downloadFileOfRF(row)">下载</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="isFirstOffLine" label="是否首次离线">
                    <template slot-scope="{row}">
                        {{ row.isFirstOffLine ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="lineStatus" label="盘匣状态">
                    <template slot-scope="{row}">
                        {{ $RfStatusList[row.lineStatus] || '' }}
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-show="step === 2 && total > size"
                style="text-align: right;"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="size"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>
            <!-- 录入存储位置 -->
            <StorageLocation v-if="step === 5" :taskInfo="taskInfo" ref="storageLocationRef" :taskId="taskId"/>

            <el-form label-width="100px" style="margin-top: 20px" v-show="step < 6">
                <el-form-item v-show="step === 3" label="打印标签:">
                    <el-button type="primary" size="small" @click="showPrintPreview">预览</el-button>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input type="textarea" v-model.trim="remark" :rows="4"/>
                </el-form-item>
            </el-form>

            <div class="btns" v-show="step < 6">
                <el-button type="primary" size="small" @click="handleCancel">任务取消</el-button>
                <el-button type="primary" :loading="nextLoading" size="small" @click="handleNext" :disabled="!taskInfo.analyzeFlag">下一步</el-button>
            </div>

            <PrintPreview ref="printPreviewRef"/>
            <FileInfo ref="fileInfoRef" @downloadFile="downloadFileOfRF2"/>


        </div>
    </div>
</template>

<script>



import PrintPreview from "./PrintPreview";
import FileInfo from "./FIleInfo";
import StorageLocation from "./StorageLocation";
import {downloadFilesOfRF} from "@/views/offline/js";

export default {
    name: "TaskProcess",
    components: {PrintPreview, FileInfo, StorageLocation},
    data() {
        return {
            tableData: [],
            loadingData: false,
            total: 0,
            currentPage: 1,
            size: 5,
            taskInfo: {},
            remark: '',
            selectedRow: [],
            taskId: '',
            allCheckedRf: [],
            rightLoading: false,
            nextLoading: false,
            refreshLoading: true,
            timeout: null
        }
    },
    computed: {
        step() {
            const {taskInfo} = this;
            if (taskInfo.step === 'POSITION_DATA') {
                return 1
            } else if (taskInfo.step === 'CONFIRM_RF') {
                return 2
            } else if (taskInfo.step === 'LABEL_PRINT') {
                return 3
            } else if (taskInfo.step === 'OFFLINE_RF') {
                return 4
            } else if (taskInfo.step === 'STORE_POSITION') {
                return 5
            } else if (taskInfo.step === 'COMPLETE') {
                return 7
            } else if (taskInfo.step === 'CANCEL') {
                return 8
            } else if (taskInfo.step === 'END') {
                return 8
            }
            return 0
        },
        steps() {
            const {taskInfo} = this;
            let list = [
                // 1 未开始  2 进行中  3 完成
                {status: 1, desc: '定位数据所在盘匣', statusDesc: '已完成'},
                {status: 2, desc: '确定离线盘匣', statusDesc: '进行中'},
                {status: 3, desc: '标签打印', statusDesc: '未开始'},
                {status: 4, desc: '取出盘匣', statusDesc: '未开始'},
                {status: 5, desc: '录入存储位置', statusDesc: '未开始'},
                {status: 6, desc: '任务完成', statusDesc: '未开始'},
                // {status: 7, desc: '任务取消', statusDesc: '未开始'},
            ]

            return taskInfo.status === 'CANCEL' ? list.slice(0,5) : list
        }
    },
    created() {
        this.taskId = this.$route.query.id
    },
    mounted() {

        if (this.taskId) {
            this.getData()
        }
    },
    beforeDestroy() {
        this.timeout && clearTimeout(this.timeout)
    },
    /**
     * /rfTask/rfSummary/{taskId}  确定离线盘匣列表
     * **/
    methods: {
        handleSelectable (row) {
            // 离线状态不可选中
            return row.lineStatus === 'ONLINE' && row.offlineSizePercent !== '100.00%'
        },
        handleSelectionChange(list, row) {
            const {size, currentPage, taskInfo, allCheckedRf} = this
            let start = (currentPage - 1) * size, end = currentPage * size
            let ids = list.map(item => item.rfid)
            for (let i = start; i < end; i++) {
                console.log('rfid:',taskInfo.rfList[i].rfid)
                let rfid = taskInfo.rfList[i].rfid
                // 如果选中了 加到所有的选中的列表
                if (ids.indexOf(rfid) > -1 && allCheckedRf.indexOf(rfid) === -1) {
                    allCheckedRf.push(rfid)
                } else if (ids.indexOf(rfid) === -1) {
                    // 如果没选中，从所有选中的列表中删除 allCheckedRf.indexOf(rfid) > -1
                    let ind = allCheckedRf.indexOf(rfid)
                    if (ind > -1) {
                        allCheckedRf.splice(ind, 1)
                    }
                }
            }
        },
        getData(isLoading = true) {
            // /rfTask/log/{taskId}
            isLoading && (this.rightLoading = true)
            this.$get('/rfTask/info/' + this.taskId)
                .then(res => {
                    if (res.code === '200') {
                        this.taskInfo = res.data
                        if (!this.taskInfo.analyzeFlag) {
                            this.timeout = setTimeout(() => {
                                this.getData(false)
                            }, 5000)
                        }
                        // 第二步 确认离线盘匣 展示盘匣列表
                        if (this.taskInfo.step === 'CONFIRM_RF') {
                            this.taskInfo.rfList && (this.taskInfo.rfList.forEach(item => {
                                // 占比100%的盘匣默认选中
                                if (item.offlineSizePercent === '100.00%' && this.allCheckedRf.indexOf(item.rfid) === -1) {
                                    this.allCheckedRf.push(item.rfid)
                                }
                            }))
                            this.handleTableData()
                        }
                    }
                })
            .finally(() => {
                this.rightLoading = false
            })
        },
        // 前端处理分页
        handleTableData () {
            const {taskInfo, currentPage, size} = this
            this.total = taskInfo.rfList ? taskInfo.rfList.length : 0
            this.tableData = taskInfo.rfList ? taskInfo.rfList.slice((currentPage - 1) * size, currentPage * size) : []

            this.handleRowSelection()
        },
        // 处理选中的  toggleRowSelection(row);
        handleRowSelection () {
            const {allCheckedRf} = this
            if (allCheckedRf.length === 0) {
                return
            }
            this.$nextTick(() => {
                this.tableData.forEach((row, ind) => {
                    if (allCheckedRf.indexOf(row.rfid)> -1) {
                        this.$refs.fileTableRef.toggleRowSelection(row, true)
                    } else {
                        this.$refs.fileTableRef.toggleRowSelection(row, false)
                    }
                })
            })
            
        },
        showPrintPreview() {
            this.$refs.printPreviewRef.show(this.taskId)
        },
        handleCancel() {
            this.$confirm("请确定是否取消本次离线任务？", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(res => {
                this.$postJson('/rfTask/step', {taskId: this.taskId, stepFlag: false, remark: this.remark})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('已取消')
                        this.getData()
                    }
                })
            })
        },
        handleCurrentChange(current) {
            this.currentPage = current
            this.handleTableData()
        },
        // 文件预览
        fileInfoPreview(r) {
            this.$refs.fileInfoRef.show(r, this.taskId)
        },
        // 盘匣内文件下载
        downloadFileOfRF (row) {
            downloadFilesOfRF(row.rfid, this.taskId)
            // this.$postJson(`/rfObject/download/${row.rfid}/${this.taskId}`, {})
            // .then(res => {
            //     const temp = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
            //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //         window.navigator.msSaveOrOpenBlob(temp, '文件清单.zip');
            //     } else {
            //         const link = document.createElement("a");
            //         link.href = URL.createObjectURL(temp);
            //         link.setAttribute("download", '文件清单.zip');
            //         document.body.appendChild(link);
            //         link.click();
            //         link.remove();
            //     }
            // })
        },
        downloadFileOfRF2 (row) {
            console.log('下载。。。')
            this.downloadFileOfRF(row)
        },

        // 下一步
        handleNext () {
            const {remark, taskInfo, step, allCheckedRf} = this;
            let params = {
                stepFlag: true,
                taskId: taskInfo.bid,
                remark
            }
            let message = '确定进入下一步吗', offlineRfidList = [], rfPositionList = []
            /**
             *  offlineRfidList  选择离线盘匣
             *  rfPositionList  录入存储位置
             * **/
            if (step === 1 || step === 3 || step === 4 || step === 6) { // 定位数据所在盘匣  打印标签  取出盘匣  任务完成
                message = '确定进入下一步吗'
            } else if (step === 2) {
                if (taskInfo.rfList.filter(item => item.lineStatus === 'OFFLINE').length === 0 && allCheckedRf.length === 0) {
                    this.$message.warning('请选择要离线的盘匣')
                    return
                } else {
                    params.offlineRfidList = allCheckedRf
                }
            } else if (step === 5) {// 录入存储位置
                let list = this.$refs.storageLocationRef.emitPosition()
                if (list.length === 0) {
                    return;
                }
                params.rfPositionList = list
            }

            //
            this.$confirm(message, {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(() => {
                this.nextLoading = true
                this.$postJson('/rfTask/step', params)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.remark = ''
                        this.getData()
                    }
                })
                .finally(() => {
                    this.nextLoading = false
                })
            })


        }
    }
}
</script>

<style scoped lang="scss">


.task-process {
    display: flex;

    .left {
        flex-basis: 240px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .right {
        flex: 1;
        padding: 20px;
        max-width: 1000px;
        .btns {
            display: flex;
            justify-content: flex-end;
        }
    }
}

/**
#1afa30
#a8f6a4
**/

@keyframes outer_blink {
    0% {
        background: rgba(168, 246, 164, 1);
    }
    50% {
        background: rgba(168, 246, 164, 0.4);
    }
    100% {
        background: rgba(168, 246, 164, 1);
    }
}

@keyframes inner_blink {
    0% {
        background: rgba(26, 250, 48, 0.6);
    }
    50% {
        background: rgba(26, 250, 48, 1);
    }
    100% {
        background: rgba(26, 250, 48, 0.6);
    }
}

.project-view-process-item {
    height: 56px;
    display: flex;
    justify-content: space-between;

    .view {
        padding-left: 80px;
        position: relative;
        padding-top: 0.1px;

        .line {
            position: absolute;
            width: 2px;
            top: 0;
            bottom: 0;
            left: 59.5px;
            background-color: #ccc;
        }

        .dot {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 18px;
            left: 50px;

            .outer {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                top: 0;
                left: 0;
                z-index: 1;
                background: #9bbffd;
            }

            .inner {
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                top: 4px;
                left: 4px;
                z-index: 2;
                background: #377ffc;
            }

            .inner.notstart {
                background: #8a8a8a;
            }

            .outer.notstart {
                background: #cdcdcd;
            }
        }

        .dot.ing {
            .outer {
                background: rgba(168, 246, 164, 1);
                animation: outer_blink 2s linear infinite;
            }

            .inner {
                background: rgba(26, 250, 48, 1);
                animation: inner_blink 2s linear infinite;
            }
        }

        .desc, .status-desc {
            line-height: 20px;
            height: 20px;
            font-size: 14px;
            color: #333;
        }

        .desc {
            margin-top: 12px;
        }

        .status-desc {
            font-size: 12px;
            color: #999;
        }

    }

    .status {
        line-height: 56px;
    }
}

.project-view-process-item:first-child {
    .line {
        top: 28px;
    }
}

.project-view-process-item:last-child {
    .line {
        bottom: 28px;
    }
}
.project-view-process-item.is-last {
    .line {
        bottom: 28px;
    }
}



</style>
