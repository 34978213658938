<template>
    <div class="task-view">
        <el-form label-width="120px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="任务名称：">
                        {{ taskInfo.name }}
                    </el-form-item>
                    <el-form-item label="创建时间：">{{ moment(taskInfo.createTime).format('YYYY-MM-DD HH:mm:ss') }}</el-form-item>
                    <el-form-item label="任务状态：">{{ taskInfo.status === 'CANCEL' ? '已取消' : taskStatusDesc(taskInfo.step) }} <el-button type="text" @click="showRemark">备注</el-button></el-form-item>
                    <el-form-item label="桶名称：">{{ taskInfo.bucket }}</el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="任务编号：">{{ taskInfo.code }}</el-form-item>
                    <el-form-item label="完成时间：">{{ moment(taskInfo.updatedAt).format('YYYY-MM-DD HH:mm:ss') }}</el-form-item>
                    <el-form-item label="任务说明：">{{ taskInfo.remark }}</el-form-item>
                    <el-form-item label="离线盘匣清单：">
                        <el-button type="text" @click="handleExportRF">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div class="tabs">
            <el-radio-group v-model="number" size="small" @change="handleTabChange">
                <el-radio-button :label="1">文件路径</el-radio-button>
                <el-radio-button :label="2">分析结果</el-radio-button>
            </el-radio-group>
        </div>
        <el-table v-show="number === 1" :data="tableData"  style="width: 100%" border>
            <el-table-column align="center" prop="bucketAndKey" label="文件路径"></el-table-column>
            <el-table-column align="center" prop="size" label="大小">
                <template slot-scope="{row}">
                    {{ bytesToSize(row.size) }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="fileCount" label="文件数量"></el-table-column>
        </el-table>
        <el-table v-show="number === 2" :data="tableData2"  style="width: 100%" border>
            <el-table-column align="center" prop="rfid" label="盘匣RFID号">
                <template slot-scope="{row}">
                    {{ row.rfid }} <span v-show="row.taskOffLineStatus" style="color: red;">*</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="das" label="所属盘库"></el-table-column>
            <el-table-column align="center" prop="offlineSizePercent" label="离线文件占比"></el-table-column>
            <el-table-column align="center" prop="lineStatus" label="盘匣状态">
                <template slot-scope="{row}"> {{ $RfStatusList[row.lineStatus] || '' }} </template>
            </el-table-column>
            <el-table-column align="center" prop="position" label="存储位置"></el-table-column>
            <el-table-column align="center" prop="file" label="文件">
                <template slot-scope="{row}">
                    <el-button type="text" @click="fileInfoPreview(row)">预览</el-button>
                    <el-button type="text" @click="handleFileDownload(row)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            style="text-align: right;"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>

        <FileInfo ref="fileInfoRef" @downloadFile="handleFileDownload"/>
        <RemarkList v-if="taskId" ref="remarkListRef" :taskId="taskId"/>
    </div>
</template>

<script>

import FileInfo from "@/views/offline/components/FIleInfo";
import RemarkList from "@/views/offline/components/RemarkList";
import moment from 'moment'
import {bytesToSize, downloadZip} from "@/utils";
import {downloadFilesOfRF, getDownloadFileName} from "@/views/offline/js";
import { taskStatusDesc } from "@/views/offline/js";

export default {
    name: "TaskView",
    components: { FileInfo, RemarkList },
    data() {
        return {
            tableData: [],
            tableData2: [],
            currentPage: 1,
            pageSize: 5,
            total: 0,
            number: 1,
            taskId: '',
            taskInfo: {}
        }
    },
    mounted() {
        this.taskId = this.$route.query.id
        if (this.taskId) {
            this.getData()
        }
    },
    methods: {
        taskStatusDesc,
        moment,
        bytesToSize,
        handleExportRF () {
            this.$postJson('/rfTask/shelves/download?taskId=' + this.taskId)
            .then(res => {
                downloadZip(res.data, getDownloadFileName(res) || '盘匣列表.xls')
            })
        },
        getData () {
            this.$get('/rfTask/info/' + this.taskId)
                .then(res => {
                    if (res.code === '200') {
                        this.taskInfo = res.data
                        this.handleTableData()
                    }
                })
        },
        handleTableData() {
            const {number, currentPage, pageSize, taskInfo} = this
            if (number === 1) {
                this.tableData = taskInfo.keyList.slice((currentPage-1) * pageSize, currentPage * pageSize)
                this.total = taskInfo.keyList.length
            } else if (number === 2) {
                this.tableData2 = taskInfo.rfList.slice((currentPage-1) * pageSize, currentPage * pageSize)
                this.total = taskInfo.rfList.length
            }

        },
        handleCurrentChange(current) {
            this.currentPage = current
            this.handleTableData()
        },
        fileInfoPreview (r) {
            this.$refs.fileInfoRef.show(r, this.taskId)
        },
        handleTabChange () {
            this.currentPage = 1
            this.handleTableData()
        },
        showRemark () {
            this.$refs.remarkListRef.show(this.taskInfo)
        },
        handleFileDownload (row) {
            downloadFilesOfRF(row.rfid, this.taskId)
        }
    }
}
</script>

<style scoped lang="scss">
.task-view {
    max-width: 1240px;
    padding: 0 20px 20px;
    .tabs {
        margin-top: 20px;
    }
}


</style>