<template>
    <div class="storage-location">
        <el-alert class="alert"
                  type="warning"
                  style="margin: 10px 0;"
                  show-icon
                  :closable="false">
            <div slot="title">
                请根据离线盘匣实际存储位置进行记录！
            </div>
        </el-alert>

        <div class="list">
            <div class="item  header">
                <div class="left">盘匣RFID号</div>
                <div class="right">存储位置</div>
            </div>
            <div class="item header" >
                <div class="left">盘匣RFID号</div>
                <div class="right">存储位置</div>
            </div>
            <div class="item" v-for="(item, ind) in rfTaskPosition">
                <div class="left">{{ item.rfid || '' }}</div>
                <div class="right">
                    <el-link style="display: block;text-underline: none!important;" icon="el-icon-edit" size="small"  class="position-item-right ellipsis"
                        v-show="item.rfid && (!item.showInput)"
                        @click="handleShowInput(item, ind)">{{ item.position }}</el-link>
                    <el-input
                        :value="item.position"
                        :id="`positionInput_${ind}`"
                        v-show="item.rfid && (item.showInput)"
                        @input="handleInputEvent($event, ind)"
                        @blur="hideInput(item, ind)"/>
                </div>
            </div>
        </div>
<!--        <el-pagination-->
<!--            v-show="rfTaskPosition.length > 10"-->
<!--            style="text-align: right;"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page.sync="currentPage"-->
<!--            :page-size="10"-->
<!--            layout="total, prev, pager, next"-->
<!--            :total="total">-->
<!--        </el-pagination>-->

    </div>
</template>

<script>
/**
 * 录入存储位置
 * **/
export default {
    name: "storage-location",
    data() {
        return {
            rfTaskPosition: [
            ],
            loadingData: false,
            currentPage: 1,
            total: 0,
        }
    },
    props: {
        taskInfo: {
            type: Object,
            default: () => ({})
        },
        taskId: {
            type: String,
            default: ''
        }
    },
    mounted() {
        if (this.taskId) {
            this.getData()
        }

    },
    methods: {
        emitPosition () {
            const {rfTaskPosition} = this;
            let count = 0
            for (let i = 0; i < rfTaskPosition.length; i++) {
                if (rfTaskPosition[i].rfid && !rfTaskPosition[i].position) {
                    count++
                }
            }
            if (count>0) {
                this.$message.warning(`还有${count}个盘匣没有录入位置，请录入后提交`)
                // 返回0个 不会提交
                return []
            }
            return rfTaskPosition
                .filter(item => item.rfid)
                .map(item => ({
                    rfid: item.rfid,
                    position: item.position
                }))
        },
        getData() {
            this.$get(`/rfTask/position/${this.taskId}`)
                .then(res => {
                    if (res.code === '200') {
                        /**
                         *  "rfid": "string",
                         *  "position": "string"
                         * **/
                        if (res.data && res.data.length > 1) {
                            this.rfTaskPosition = res.data
                        } else  {
                            this.rfTaskPosition = [...res.data, {}]
                        }

                    }
                })
        },
        handleCurrentChange(current) {
            this.currentPage = current
        },
        handleShowInput (item, ind) {
            this.$set(this.rfTaskPosition[ind], 'showInput', !item.showInput)
            setTimeout(() => {
                document.getElementById(`positionInput_${ind}`).focus()
            }, 100)
        },
        hideInput (item, ind) {
            this.$set(this.rfTaskPosition[ind], 'showInput', false)
        },
        handleInputEvent (val, ind) {
            this.$set(this.rfTaskPosition[ind], 'position', val)
        }
    }
}
</script>

<style scoped lang="scss">

.list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px;

    .item {
        width: 50%;
        text-align: center;
        height: 42px;
        line-height: 42px;
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        display: flex;
        .left{
            width: 50%;
            border-right: 1px solid #ebeef5;
        }
        .right {
            width: 50%;
            cursor: pointer;
            padding: 0 20px;
        }
    }
    .item:first-child, .item:nth-child(2) {
        height: 38px;
        line-height: 38px;
        padding: 0;
        background: #f4f7ff;
    }

    .item:nth-child(2n) {
        border-right: 1px solid #ebeef5;
    }

    .item:nth-last-child(2) {
        border-bottom: 1px solid #ebeef5;
    }

    .item:last-child {
        border-bottom: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }
}

.position-item-right:hover {
    text-underline: none;
    line-height: 20px;
    margin-top: 11px;
}

</style>